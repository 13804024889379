<template lang="pug">
div
  .thank-you-box
    b-row
      b-col.text-center
        img(:src="wuwowThankyouPng" alt="WUWOW 線上英語教學 感謝您")
    b-row.mt-5
      b-col
        span.thank-you-content
          | 謝謝您提供的訊息，我們已經收到您的資料！
          br
          | 我們會盡快安排專員與您聯絡！
    b-row.mt-5
      b-col.thank-button-group
        b-button.go-back-home-btn.m-bottom-5( variant="primary" href="https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/Fast+guide/202305_jr_cambridge_english_starters_words_book.pdf" target="download") 下載 劍橋兒童英檢單字書
        b-button.go-back-home-btn(@click="backToHome()" variant="outline-primary") 返回WUWOW Jr官網
</template>

<script>
import wuwowThankyouPng from '@/assets/wuwow_ty.png';
export default {
  name: 'ThankYouPage',

  data() {
    return {
      wuwowThankyouPng: wuwowThankyouPng,
    };
  },

  mounted(){
  },

  methods: {
    backToHome(){
      this.$router.push({ path: '/' });
    },
  },
};
</script>
<style lang="scss" scoped>
.thank-you-box{
  height: 100vh;
  text-align: center;
  background-color: #F5F5F5;
  background-image: linear-gradient(0deg, rgba(223, 243, 248, 0.8), rgba(223, 243, 248, 0.8)), url(~@/assets/event/cross-industry/thank.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.thank-you-content{
  font-size: 18px;
  color: var( --text-light-color );
}
.thank-button-group {
  max-width: 322px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.m-bottom-5 {
  margin-bottom: 20px;
}
.go-back-home-btn{
  font-size: 16px;
  padding: 15px 0px;
}
</style>
